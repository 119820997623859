import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainNavy,
  innerWidth,
  mainViolet,
  screen,
} from "../../components/common/variables";
import Nav from "../../components/nav";
import StyledButton from "../../components/styled-button";
import ProgressBar from "../../components/progress-bar";
import BottomNav from "../../components/bottom-nav";
import { MdArrowForward } from "react-icons/md";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  overflow: auto;
  color: ${mainWhite};
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 140px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 140px 0 0 0;
    }

    .select {
      margin: 0 70px 0 0;
      max-width: 900px;
      width: 100%;
      position: relative;
      cursor: pointer;

      .placeholder {
        color: #a8acb2;
        font-size: 1.76rem;
        border-bottom: 2px #a8acb2 solid;
      }

      .arrow {
        position: absolute;
        right: 0;
        top: 2px;

        svg {
          color: #a8acb2;
          font-size: 2.6rem;
        }
      }

      .dropdown-list {
        position: absolute;
        left: 0;
        top: 48px;
        background: #fff;
        width: 600px;
        padding: 29px 27px;

        li {
          color: #172637;
          font-size: 1.1rem;
          margin: 0 0 13px 0;
          list-style-type: none;
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainViolet};
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      &--active {
        .placeholder {
          color: #fff;

          border-bottom: 2px #fff solid;
        }

        .arrow {
          svg {
            color: #fff;
          }
        }
      }

      ul {
        padding: 25px 0 0 50px;
        li {
          list-style-type: disc;
          font-size: 1.12rem;
          margin: 0 0 14px 0;
        }
      }
    }

    .btns {
      &__each {
        margin: 0 0 14px 0;

        &--disabled {
          a {
            background: #848b93;
            border: 2px #848b93 solid;
            color: #a5a9ad;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

const DiscoverOne = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [select, setSelect] = useState("I want to…");

  const isDisabled = () => {
    if (select === "I want to…") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Layout>
      <SEO title="Manage - 1" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList}>
          <ProgressBar>
            <h2 className="title">MANAGE</h2>
            <div className="bars">
              <p className="selected">1. Test</p>
              <p className="selected selected--not-selected">2. Test against</p>
              <p className="selected selected--not-selected">3. Report</p>
            </div>
          </ProgressBar>

          <div className="inner-wrapper">
            <motion.div
              variants={variantsItem}
              className={isDisabled() ? "select" : "select select--active"}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <p className="placeholder">{select}</p>

              <span className="arrow">
                <MdArrowForward />
              </span>

              {isDropdownOpen && (
                <ul className="dropdown-list">
                  <li
                    onClick={() =>
                      setSelect("Test my applications against a Windows build​")
                    }
                  >
                    Test my applications against a Windows build​
                  </li>
                  <li
                    onClick={() =>
                      setSelect(
                        "Test my applications against a cumulative update(s)​"
                      )
                    }
                  >
                    Test my applications against a cumulative update(s)​
                  </li>

                  <li onClick={() => setSelect("I want to…")}>
                    Test my applications against any change​
                  </li>
                </ul>
              )}
              <ul>
                <li>
                  Deploy fully tested quality packages with complete confidence
                  (pre-deployment testing).​
                </li>
                <li>
                  Manage production packages by continually testing against
                  cumulative updates and new Windows feature releases (ongoing
                  production testing).​
                </li>
                <li>Ensure effective automation to manage change.</li>
              </ul>
            </motion.div>
            <motion.div className="btns" variants={variantsItem}>
              <StyledButton
                to={`/manage/${
                  select === "Test my applications against a Windows build​"
                    ? "2/"
                    : "2-5/"
                }`}
                className={
                  isDisabled()
                    ? "btns__each btns__each--disabled"
                    : "btns__each"
                }
              >
                NEXT
              </StyledButton>
            </motion.div>
          </div>
        </motion.div>

        <BottomNav
          leftLabel={"BACK"}
          leftLink={"/goals/"}
          rightLabel={"BACK TO START"}
          rightLink={"/"}
        />
      </Wrapper>
    </Layout>
  );
};

export default DiscoverOne;
